import { Suspense, lazy } from 'react'
import LazyLoading from '../components/LazyLoading'
import { Navigate, Outlet } from 'react-router-dom'
import { useCurrentUserContext } from '../contexts/CurrentUserContext'
import PublicHomepage from 'pages/home/PublicHomepage'
import { ManualVerificationInstructions } from '../pages/ChooseAuthenticationMethodPage'
import Layout from 'components/Layout'
import { Callback } from 'components/Callback'

const Page404 = lazy(async () => await import('../pages/Page404'))
const HomePage = lazy(async () => await import('../pages/home'))
const UserPage = lazy(async () => await import('../pages/user'))
const UserInformationPage = lazy(async () => await import('../pages/user/showUser'))
const UserEditPage = lazy(async () => await import('../pages/user/editUser'))
const UserCompanyInformationPage = lazy(async () => await import('../pages/user/showCompanyInformation'))
const UserCompanyEditPage = lazy(async () => await import('../pages/user/editCompanyInformation'))
const UserConsents = lazy(async () => await import('../pages/user/consents'))
const RegisterPage = lazy(async () => await import('../pages/register/additionalInformation'))
const RegisterSuccess = lazy(async () => await import('../pages/register/success'))
const ChooseAuthenticationMethodPage = lazy(async () => await import('../pages/ChooseAuthenticationMethodPage'))
const VerifyEmail = lazy(async () => await import('../pages/user/verifyEmail'))
const CourseInvitationLandingPage = lazy(async () => await import('../pages/invitations/course'))
const CourseInvitationUpdateProfile = lazy(async () => await import('../pages/invitations/course/CourseInvitationUpdateProfile'))
const GDPRPage = lazy(async () => await import('../pages/gdpr'))
const DataSharingPage = lazy(async () => await import('../pages/learningDataSharingAgreements'))
const DataSharingAgreementsPage = lazy(async () => await import('../pages/learningDataSharingAgreements/agreement'))
const DataSharingReportsPage = lazy(async () => await import('../pages/learningDataSharingAgreements/reports'))
const SupervisorInvitationLandingPage = lazy(async () => await import('../pages/invitations/supervisor'))
const LDSAConsentLandingPage = lazy(async () => await import('../pages/invitations/ldsaConsent/landing'))
const LDSAConsent = lazy(async () => await import('../pages/invitations/ldsaConsent/giveConsent'))
const SendVerifyEmail = lazy(async () => await import('../pages/user/sendVerifyEmail'))
const ProvisioningAgreementsPage = lazy(async () => await import('../pages/provisioningAgreements'))
const ChangeEmailPage = lazy(async () => await import('../pages/ChangeEmailPage'))
const UserAccountExistsPage = lazy(async () => await import('../pages/UserAccountExistsPage'))
const CustomSignupPage = lazy(async () => await import('../pages/register/customSignup'))
const CustomSignupSuccessPage = lazy(async () => await import('../pages/register/customSignup/success'))
const LoginErrorPage = lazy(async () => await import('../pages/LoginErrorPage'))

interface RequireAuthProps {
  children: JSX.Element
  requireDataSharingSupervisor?: boolean
  requireParticipant?: boolean
  requireInstructor?: boolean
  requireProvisioningSupervisor?: boolean
}

function RequireAuth ({
  children,
  requireDataSharingSupervisor = false,
  requireParticipant = false,
  requireInstructor = false,
  requireProvisioningSupervisor = false
}: RequireAuthProps) {
  const { profile, authenticated, permissions, profileLoading } = useCurrentUserContext()

  if (profileLoading) {
    return <LazyLoading />
  }

  if (!authenticated) {
    return <PublicHomepage />
  }

  // Apparently the userId can be null even when authenticated
  if (!profile.userId) {
    return null
  }

  const missingRequiredPermission =
    (requireDataSharingSupervisor && !permissions.isDataSharingSupervisor) ||
    (requireParticipant && !permissions.isParticipant) ||
    (requireInstructor && !permissions.isInstructor) ||
    (requireProvisioningSupervisor && !permissions.isProvisioningSupervisor)

  if (missingRequiredPermission) {
    return <Navigate to='/' />
  }

  return children
}

export const Routes = [
  {
    element: (
      <Suspense fallback={<LazyLoading />}>
        <Layout />
      </Suspense>
    ),
    children: [
      {
        path: '/',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <HomePage />
          </Suspense>
        )
      },
      {
        path: 'callback',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <Callback />
          </Suspense>
        )
      },
      {
        path: 'user',
        element: (
          <RequireAuth>
            <>
              <Suspense fallback={<LazyLoading />}>
                <UserPage />
              </Suspense>
              <Outlet />
            </>
          </RequireAuth>
        ),

        children: [
          {
            element: (
              <Navigate replace to='profile' />
            ),
            index: true
          },
          {
            path: 'profile',
            element: (
              <RequireAuth>
                <Suspense fallback={<LazyLoading />}>
                  <UserInformationPage />
                </Suspense>
              </RequireAuth>
            )
          },
          {
            path: 'profile/edit',
            element: (
              <RequireAuth>
                <Suspense fallback={<LazyLoading />}>
                  <UserEditPage />
                </Suspense>
              </RequireAuth>
            )
          },
          {
            path: 'company',
            element: (
              <RequireAuth>
                <Suspense fallback={<LazyLoading />}>
                  <UserCompanyInformationPage />
                </Suspense>
              </RequireAuth>
            )
          },
          {
            path: 'company/edit',
            element: (
              <RequireAuth>
                <Suspense fallback={<LazyLoading />}>
                  <UserCompanyEditPage />
                </Suspense>
              </RequireAuth>
            )
          },
          {
            path: 'consents',
            element: (
              <RequireAuth>
                <Suspense fallback={<LazyLoading />}>
                  <UserConsents />
                </Suspense>
              </RequireAuth>
            )
          }
        ]
      },
      {
        path: 'user/profile/gdpr',
        element: (
          <RequireAuth>
            <Suspense fallback={<LazyLoading />}>
              <GDPRPage />
            </Suspense>
          </RequireAuth>
        )
      },
      {
        path: 'course-invitation',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <CourseInvitationLandingPage />
          </Suspense>
        )
      },
      {
        path: 'course-invitation-update-profile',
        element: (
          <RequireAuth>
            <Suspense fallback={<LazyLoading />}>
              <CourseInvitationUpdateProfile />
            </Suspense>
          </RequireAuth>
        )
      },
      {
        path: 'courses',
        async lazy () {
          const { default: CoursesPage } = await import('../pages/courses')
          return {
            element: (
              <RequireAuth requireParticipant>
                <CoursesPage />
              </RequireAuth>
            )
          }
        }
      },
      ...(['upcoming', 'completed', 'failed', 'achievements'] as const).map(activeTabId => (
        {
          path: `courses/${activeTabId}`,
          async lazy () {
            const { default: CoursesPage } = await import('../pages/courses')
            return {
              element: (
                <RequireAuth requireParticipant>
                  <Suspense fallback={<LazyLoading />}>
                    <CoursesPage activeTabId={activeTabId} />
                  </Suspense>
                </RequireAuth>
              )
            }
          }
        }
      )),
      {
        path: 'strong-auth/manual-instructions',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <ManualVerificationInstructions />
          </Suspense>
        )
      },
      {
        path: 'strong-auth',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <ChooseAuthenticationMethodPage />
          </Suspense>
        )
      },
      {
        path: 'data-sharing',
        element: (
          <RequireAuth requireDataSharingSupervisor>
            <>
              <Suspense fallback={<LazyLoading />}>
                <DataSharingPage />
              </Suspense>
              <Outlet />
            </>
          </RequireAuth>
        ),
        children: [
          {
            element: (
              <Navigate replace to='invitations' />
            ),
            index: true
          },
          {
            path: 'invitations',
            element: (
              <RequireAuth requireDataSharingSupervisor>
                <Suspense fallback={<LazyLoading />}>
                  <DataSharingAgreementsPage />
                </Suspense>
              </RequireAuth>
            )
          },
          {
            path: 'reports',
            element: (
              <RequireAuth requireDataSharingSupervisor>
                <Suspense fallback={<LazyLoading />}>
                  <DataSharingReportsPage />
                </Suspense>
              </RequireAuth>
            )
          }
        ]
      },
      {
        path: 'provisioning',
        element: (
          <RequireAuth requireProvisioningSupervisor>
            <Outlet />
          </RequireAuth>
        ),
        children: [
          {
            element: (
              <Navigate replace to='invitations' />
            ),
            index: true
          },
          {
            path: 'invitations',
            element: (
              <RequireAuth requireProvisioningSupervisor>
                <ProvisioningAgreementsPage />
              </RequireAuth>
            )
          }
        ]
      },
      {
        path: 'register',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <RegisterPage />
          </Suspense>
        )
      },
      {
        path: 'register/success',
        element: (
          <RequireAuth>
            <Suspense fallback={<LazyLoading />}>
              <RegisterSuccess />
            </Suspense>
          </RequireAuth>
        )
      },
      {
        path: 'verify',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <VerifyEmail />
          </Suspense>
        )
      },
      {
        path: 'send-verify-email',
        element: (
          <RequireAuth>
            <Suspense fallback={<LazyLoading />}>
              <SendVerifyEmail />
            </Suspense>
          </RequireAuth>
        )
      },
      {
        path: 'change-email',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <ChangeEmailPage />
          </Suspense>
        )
      },
      {
        path: 'user-account-exists',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <UserAccountExistsPage />
          </Suspense>
        )
      },
      {
        path: 'ldsa-invitation',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <LDSAConsentLandingPage />
          </Suspense>
        )
      },
      {
        path: 'ldsa-consent',
        element: (
          <RequireAuth>
            <Suspense fallback={<LazyLoading />}>
              <LDSAConsent />
            </Suspense>
          </RequireAuth>
        )
      },
      {
        path: 'supervisor-invitation',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <SupervisorInvitationLandingPage />
          </Suspense>
        )
      },
      {
        path: 'custom-signup',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <CustomSignupPage />
          </Suspense>
        )
      },
      {
        path: 'custom-signup/success',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <CustomSignupSuccessPage />
          </Suspense>
        )
      },
      {
        path: 'login-error',
        element: (
          <Suspense fallback={<LazyLoading />}>
            <LoginErrorPage />
          </Suspense>
        )
      }
    ]
  },
  {
    path: '*',
    element: (
      <Suspense fallback={<LazyLoading />}>
        <Page404 />
      </Suspense>
    )
  }
]
